
import {defineComponent} from "vue";

export default defineComponent({
  name: "AttachmentFileButton",
  setup() {

  },
  emits: ['selected'],
  methods: {
    onFileSelected(event) {
      const file = event.target.files[0];
      // Do something with the fil
      this.$emit('selected', file)
    },
  }
})
