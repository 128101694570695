
import { defineComponent, ref } from "vue";
import { ISearchFilter } from "@/core/entity/ISearchFilter";
import { ISearchField } from "@/core/entity/ISearchField";
import { OPERATOR, SEARCH_ENTITY } from "@/core/config/Constant";
import PolicyService from "@/core/services/PolicyService";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import { InsuredSelect } from "@/core/composite/composite";
import CaseService from "@/core/services/CaseService";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";

export default defineComponent({
  name: "FormMoveCase",
  components: {PolicyStatus, BaseSelect, BaseForm},
  props: {
    caze: {type: Object}
  },
  emits: ['movedCase'],
  setup(props, {emit}) {
    const refCase = ref(props.caze)
    const moveCaseModel = ref<any>({policyId: '', insuredId: ''});
    const policyData = ref<any>({data: [], total: 0, pages: 0})
    const policyField: ISearchField[] = [
      {column: 'clientId', type: 'HIDDEN', operator: OPERATOR.EQ, value: refCase.value?.client?.id, name: ''},
      {column: 'policyNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Policy#'},
      {column: 'certificate', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Certificate'},
    ]
    const terms = ref({number: '', certificate: ''});
    const searching = ref(false);
    const submitting = ref(false);
    const filter = ref<ISearchFilter>({
      paginationPage: 1,
      paginationSize: 20,
      query: policyField,
      entity: SEARCH_ENTITY.POLICY_VIEW,
      fields: [],
      expands: [],
    })
    const search = () => {
      searching.value = true
      PolicyService.search(filter.value).then(res => {
        policyData.value = res;
      }).finally(() => {
        searching.value = false;
      })
    }

    const move = () => {
      submitting.value = true;
      CaseService.moveCase(refCase?.value?.id, moveCaseModel.value).then(res => {
        emit('movedCase', res);
      }).finally(() => {
        submitting.value = false
      })
    }
    return {
      policyData,
      terms,
      filter,
      searching,
      moveCaseModel,
      search,
      ...InsuredSelect(''),
      submitting,
      move,
    }
  },
})
