
import {defineComponent, ref, watch} from 'vue'
import ProcessService from "@/core/services/ProcessService";
import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnums";
import ProcessType from "@/views/process/ProcessType.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "EntityProcessList",
  components: {EntityLoading, ProcessType},
  props: {
    object: {}
  },
  setup(props) {
    const data = ref<any>([])
    const loading = ref(true);
    const load = (object) => {
      loading.value = true;
      ProcessService.getActiveProcesses(object.objectId, object.objectType).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    watch(() => props.object, cb => {
      load(cb)
    })
    load(props.object)

    store.subscribe((mutation) => {
      if (mutation.type === Mutations.ADDED_PROCESS) {
        load(props.object)
      }
    });
    return {
      load,
      data,
      loading
    }
  }
})
