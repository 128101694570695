import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex-grow-1 me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_ProcessType = _resolveComponent("ProcessType")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "d-flex align-items-center notice d-flex rounded border border-dashed p-6 bg-light-success border-success mb-2",
          key: item.id
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ProcessType, {
              type: item.type
            }, null, 8, ["type"]),
            _createVNode(_component_router_link, {
              to: `/processes/`+ item.id,
              class: "fs-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]))
      }), 128))
}