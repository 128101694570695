
import { defineComponent, ref, watch } from "vue";
import CaseService from "@/core/services/CaseService";
import { LoadPanel } from "@/core/composite/composite";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "HasOtherCases",
  components: {InfoBox},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref([]);
    const load = (id) => {
      CaseService.hasCase(id).then(res => {
        data.value = res
      })
    }
    load(props.id)
    watch(() => props.id, (cb) => {
      load(cb)
    })
    return {
      data,
      ...LoadPanel()
    }
  }
})
