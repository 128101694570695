
import {defineComponent} from 'vue'
import Swal from "sweetalert2";
import {useToast} from "vue-toast-notification";
import ScanService from "@/core/services/ScanService";

export default defineComponent({
  name: "ScanEventMenu",
  props: {
    object: {type: Object, required: true}
  },
  methods: {
    onScan() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      ScanService.event(this.object.objectType, this.object.objectId).then(() => {
        useToast().success('Scan successfully', {position: 'top-right'})
      }).finally(() => {
        Swal.close();
      })
    },
  }
})
